import  { createContext, useEffect, useReducer } from 'react'

export const AuthContext =  createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, user: action.payload }
        case 'LOGOUT':
            return { ...state, user: null }
        case 'AUTH_READY':
            return { ...state, user: action.payload, authIsReady: true }
        default: 
            return state
    }
}

// eslint-disable-next-line
export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        authIsReady: false
    })

    useEffect(() => {
        const userDetails = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')) : null

        // const checkUser = () => {
        //     axios.post('http://wagwan-api.test/api/check_user', userDetails, {
        //             headers: {
        //                 "Accept": "application/json",
        //                 "Authorization" : `Bearer ${userDetails.access_token}`
        //             },
        //         })
        //         .then(({data})=>{
        //             dispatch({ type: 'AUTH_READY', payload: data})
        //         })
        // }
        // checkUser()

        dispatch({ type: 'AUTH_READY', payload: userDetails})
    }, [])

    return (
        <AuthContext.Provider value={{...state,  dispatch }}>
            { children }
        </AuthContext.Provider>
    )
}

