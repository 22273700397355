// AllPastorPages.js

import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DatabaseContext } from '../Database/Database';

function AllPastorPages() {
  const { id } = useParams(); // Get the dynamic parameter from the URL
  const { AllPastorPages } = useContext(DatabaseContext);
  const pastor = AllPastorPages.find((p) => p.id === id);

  if (!pastor) {
    // Handle case when pastor ID is not found
    return <div>Pastor not found</div>;
  }

  return (
    <section className="paddingtop">
      <div className="container">
        <div className="pastorjames-details">
        {pastor.id === 'Gideon' ? (
            <div className="pastorjames-details-name">
              <p>Rev. <br /> {pastor.name}</p>
              <div className="pastorjames-details-name-logo"></div>
            </div>
          ) : (
            <div className="pastorjames-details-name">
              <p>Pastor <br /> {pastor.name}</p>
              <div className="pastorjames-details-name-logo"></div>
            </div>
          )}
          <div className="pastorjames-details-bg">
            <div className="pastor-details-bg-pic">
              <img src={pastor.image} alt={pastor.name} />
            </div>
          </div>
        </div>
        <div className="pastorjames-profile">
          <p>{pastor.profile_details}</p>
        </div>
        <div className="pastorjames-button">
          <Link className="btn btn-primary" to="/all-pastors">
            BACK TO PASTOR
          </Link>
        </div>
      </div>
    </section>
  );
}

export default AllPastorPages;
