import { Link } from "react-router-dom";
import { DatabaseContext } from "../Database/Database";
import { useContext } from "react";






export default function AllPastors() {

    const { PastorsData } = useContext(DatabaseContext);

    return (

        <section>
            <div className="pastorates-section general-margin">
                <div className="container">
                    <div className="pastorates-section-heading">
                        <p>Pastoral Team</p>
                    </div>

                    <div className="pastorates-section-flex">
                        <div className="pastorates-section-flex-box pastorates-section-flexmargin" >
                            <div className="pastorates-section-flex-box-flex">
                                <div className="pastorates-section-flex-box-flex-image"
                                
                                    style={{
                                        backgroundImage: "url('/images/pastors_image/Pastor 8.jpg')",

                                    }}
                                >

                                </div>
                                <div className="pastorates-section-flex-box-flex-name">
                                    <p className="pastorates-section-flex-box-flex-name-name">Pst. <br /> Jame Oluwasanmi</p>
                                    <p className="pastorates-section-flex-box-flex-name-profile">
                                        <Link to="/our-pastor" style={{ color: "#0192ff" }}>VIEW PROFILE <i className="fa-solid fa-arrow-right"></i></Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        {PastorsData.map((pastor) => (
                            <div key={pastor.id} className="pastorates-section-flex-box pastorates-section-flexmargin">
                                <div className="pastorates-section-flex-box-flex">
                                    <div className="pastorates-section-flex-box-flex-image"
                                        style={{
                                            backgroundImage: `url("${pastor.image}")`,
                                        }}
                                    >
                                    </div>
                                    <div className="pastorates-section-flex-box-flex-name">
                                        {pastor.id === 'Gideon' ? (
                                            <p className="pastorates-section-flex-box-flex-name-name">Rev. <br /> {pastor.name}</p>
                                        ) : (
                                            <p className="pastorates-section-flex-box-flex-name-name">Pst. <br /> {pastor.name}</p>
                                        )}
                                        <p className="pastorates-section-flex-box-flex-name-profile">
                                            <Link
                                                to={`/pastor/${pastor.id}`}
                                                style={{ color: '#0192ff' }}
                                            >
                                                VIEW PROFILE <i className="fa-solid fa-arrow-right"></i>
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* <div className="pastorates-section-flex-box pastorates-section-flexmargin">
                            <div className="pastorates-section-flex-box-flex">
                                <div className="pastorates-section-flex-box-flex-image pastorates-section-flex-box-flex-osas">
                                    <img src="/images/pastors_image/Pastor 5.jpg" alt="" />
                                </div>
                                <div className="pastorates-section-flex-box-flex-name">
                                    <p className="pastorates-section-flex-box-flex-name-name">Pst. <br/> Osas Oluwasanmi</p>
                                    <p className="pastorates-section-flex-box-flex-name-profile">VIEW PROFILE <i className="fa-solid fa-arrow-right"></i></p>
                                </div>
                            </div>
                        </div>
                        <div className="pastorates-section-flex-box pastorates-section-flexmargin">
                            <div className="pastorates-section-flex-box-flex">
                                <div className="pastorates-section-flex-box-flex-image pastorates-section-flex-box-flex-aanu">
                                <img src="/images/pastors_image/Pastor 7.jpg" alt="" />
                                </div>
                                <div className="pastorates-section-flex-box-flex-name">
                                    <p className="pastorates-section-flex-box-flex-name-name">Pst. <br/> Aanuoluwapo Awesome</p>
                                    <p className="pastorates-section-flex-box-flex-name-profile">VIEW PROFILE <i className="fa-solid fa-arrow-right"></i></p>
                                </div>
                            </div>
                        </div>
                        <div className="pastorates-section-flex-box pastorates-section-flexmargin">
                            <div className="pastorates-section-flex-box-flex">
                                <div className="pastorates-section-flex-box-flex-image pastorates-section-flex-box-flex-rev">
                                <img src="/images/pastors_image/Pastor 2.jpg" alt="" />
                                </div>
                                <div className="pastorates-section-flex-box-flex-name">
                                    <p className="pastorates-section-flex-box-flex-name-name">Rev. <br/> Gideon Olatide</p>
                                    <p className="pastorates-section-flex-box-flex-name-profile">VIEW PROFILE <i className="fa-solid fa-arrow-right"></i></p>
                                </div>
                            </div>
                        </div>
                        <div className="pastorates-section-flex-box pastorates-section-flexmargin">
                            <div className="pastorates-section-flex-box-flex">
                                <div className="pastorates-section-flex-box-flex-image pastorates-section-flex-box-flex-dupe">
                                <img src="/images/pastors_image/Pastor 4.jpg" alt="" />
                                </div>
                                <div className="pastorates-section-flex-box-flex-name">
                                    <p className="pastorates-section-flex-box-flex-name-name">Pst. <br/> Dupe Kujo</p>
                                    <p className="pastorates-section-flex-box-flex-name-profile">VIEW PROFILE <i className="fa-solid fa-arrow-right"></i></p>
                                </div>
                            </div>
                        </div>
                        <div className="pastorates-section-flex-box pastorates-section-flexmargin">
                            <div className="pastorates-section-flex-box-flex">
                                <div className="pastorates-section-flex-box-flex-image pastorates-section-flex-box-flex-kujo">
                                <img src="/images/pastors_image/Pastor 3.jpg" alt="" />
                                </div>
                                <div className="pastorates-section-flex-box-flex-name">
                                    <p className="pastorates-section-flex-box-flex-name-name">Pst. <br/> Clement Kujo</p>
                                    <p className="pastorates-section-flex-box-flex-name-profile">VIEW PROFILE <i className="fa-solid fa-arrow-right"></i></p>
                                </div>
                            </div>
                        </div>
                        <div className="pastorates-section-flex-box pastorates-section-flexmargin">
                            <div className="pastorates-section-flex-box-flex">
                                <div className="pastorates-section-flex-box-flex-image pastorates-section-flex-box-flex-jide">
                                <img src="/images/pastors_image/Pastor 6.jpg" alt="" />
                                </div>
                                <div className="pastorates-section-flex-box-flex-name">
                                    <p className="pastorates-section-flex-box-flex-name-name">Pst. <br/> Babajide Odeyinka</p>
                                    <p className="pastorates-section-flex-box-flex-name-profile">VIEW PROFILE <i className="fa-solid fa-arrow-right"></i></p>
                                </div>
                            </div>
                        </div>
                        <div className="pastorates-section-flex-box pastorates-section-flexmargin">
                            <div className="pastorates-section-flex-box-flex">
                                <div className="pastorates-section-flex-box-flex-image pastorates-section-flex-box-flex-essien">
                                <img src="/images/pastors_image/Pastor 1.jpg" alt="" />
                                </div>
                                <div className="pastorates-section-flex-box-flex-name">
                                    <p className="pastorates-section-flex-box-flex-name-name">Pst. <br/> Samuel Essien</p>
                                    <p className="pastorates-section-flex-box-flex-name-profile">VIEW PROFILE <i className="fa-solid fa-arrow-right"></i></p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
}