import { BrowserRouter, Routes, Route } from "react-router-dom";
import Database from './Database/Database';
import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "./Pages/Layout";
import Home from "./Pages/HomePage";
import About from "./Pages/About";
import AllPastors from "./Pages/AllPastors";
import Donations from "./Pages/Donation";
import Resources from "./Pages/Resources";
import Sermon from "./Pages/Sermon/Media";
import { Ministry } from "./Pages/Ministry";
import  SingleEventPage  from "./Pages/HomePage/SingleEventPage";
// import reactLogo from './assets/react.svg' // was in assets
// import viteLogo from '/vite.svg' // was in public
import './App.css'
// import DonationPage from "./Pages/DonationPage";
import OurPastorPage from "./Pages/OurPastorPage";
import AllPastorPages from "./Pages/AllPastorPages";
import EventPage from "./Pages/EventPage";
import AllministryPage from "./Pages/Ministry/AllministryPage";


// Create Context Object


function App() {
  return (
    <Database  routes={
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="/event/:id" element={<SingleEventPage />} />
            <Route path="ministry" element={<Ministry />} />
            <Route path="ministries" element={<AllministryPage />} />
            <Route path="contact" element={<Resources />} />
            <Route path="prayers" element={<Resources />} />
            <Route path="testimony" element={<Resources />} />
            <Route path="sermons/video" element={<Sermon  />} />
            {/* <Route path="sermons/audio" element={<Sermon  />} /> */}
            <Route path="all-pastors" element={<AllPastors  />} />
            <Route path="pastor/:id" element={<AllPastorPages  />} />
            <Route path="our-pastor" element={<OurPastorPage  />} />
            <Route path="donation" element={<Donations  />} />
            <Route path="event-page" element={<EventPage  />} />
          </Route>


        </Routes>
      </BrowserRouter>
    } />
  )
}

export default App
