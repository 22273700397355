import Hero from "./Hero"
import BlogSermon from "./BlogSermon"
import  UpcomingEvents  from "./Upcoming"
import OurPastor from "./OurPastor"
import { 
    Upcoming,
    Donate, 
    Gallery, 
    GDCWatch ,
    Ministries,

} from "./Components"

function HomePage() {
    
    return <>
        <Hero />
        <Upcoming />
        <BlogSermon />
        <UpcomingEvents />
        <OurPastor  />
        <Donate />
        <Gallery />
        <GDCWatch />
        <Ministries />
        
    </>
}

export default HomePage


