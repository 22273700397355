import { useParams } from 'react-router-dom';
import { appURL } from '../../env';
import axios from 'axios';
import { useState, useEffect } from 'react';
const SingleEventPage = () => {
    let { id } = useParams();

    const [event, SetEvent] = useState(null);
    const [upcome_event, SetUpcomeEvent] = useState(null);


    const formatTime = (timeString) => {
        const options = { hour: 'numeric', minute: '2-digit', hour12: true };
        return new Date(`1970-01-01T${timeString}`).toLocaleTimeString([], options);
    };

    const formatDate = (dateString) => {
        const options = { weekday: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString([], options);
    };

    useEffect(() => {
        axios.get(`${appURL}/admin/event/upcome-event-webpage`, {
            headers: {
                Accept: 'application/json',
            },
        })
            .then((res) => {
                SetUpcomeEvent(res.data.data);
            })
            .catch((error) => {
                console.log(error.response.data.message);
            });
        // function to get upcoming event
        //     <div className="text-center has-margin-xs-top"><a
        //     className="btn btn-primary btn-lg" href="#" role="button">Join event
        //     →</a>
        // </div>
        axios.get(`${appURL}/admin/event/get_event_for_webpage/${id}`, {
            headers: {
                Accept: 'application/json',
            },
        })
            .then((res) => {
                SetEvent(res.data);
                // console.log(res.data, 111);
            })
            .catch((error) => {
                console.log(error.response.data.message);
            });
    }, []);
    return (
        <>
            <div className="subpage-head has-margin-bottom">
                <div className="container">
                    <h3>Upcoming Event Heading</h3>
                    <p className="lead">on {upcome_event && formatDate(upcome_event.date)} <a href="#" className="link-reverse">at {" "}
                        {upcome_event && upcome_event.location}</a></p>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-9 has-margin-bottom">
                        <article className="blog-content">
                            <img src={event && event.event_image}
                                alt="church event" className="img-responsive has-margin-xs-bottom"
                            />
                            <div className="embed-responsive embed-responsive-16by9 has-margin-xs-bottom">
                                <iframe
                                    className="embed-responsive-item"
                                    src="/images/VID-20230825-WA0009.mp4"
                                    width="620"
                                    height="349"
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe>
                            </div>
                            <p className="lead">{event && event.name}</p>
                            <p>{event && event.description}</p>

                        </article>
                    </div> */}
                    <div className="col-md-9 has-margin-bottom">
                        <article className="blog-content">
                            {event && event.name === 'Apostolic Invasion' ? (
                                <div className="embed-responsive embed-responsive-16by9 has-margin-xs-bottom">
                                    <iframe
                                        className="embed-responsive-item"
                                        src="/images/VID-20230825-WA0009.mp4"
                                        width="620"
                                        height="349"
                                        frameBorder="0"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            ) : (
                                <img src={event && event.event_image}
                                    alt="church event" className="img-responsive has-margin-xs-bottom"
                                />
                            )}
                            <p className="lead">{event && event.name}</p>
                            {event && event.name ==='Apostolic Invasion' ? (
                                <div className="apostolic-invasion-section">
                                <p className="general-bold apostolic-invasion-title">Apostolic Invasion: A 3-Day Encounter with Apostle Arome Osayi</p>
                                <p>Welcome to the <b>&rsquo;Apostolic Invasion&rsquo;</b> program hosted by Grace Definition Church in Ijebu Ode,
                                  Ogun State. Get ready for an incredible three days of transformative teachings, fervent worship, and spiritual empowerment as we welcome the esteemed Apostle Arome Osayi. This event promises to be a life-changing experience
                                  as we delve into the depths of God&apos;s Word, seeking a fresh encounter with His presence.
                                </p>
                                <p><b>About Apostle Arome Osayi:</b> Apostle Arome Osayi is a renowned Christian minister,
                                  speaker, and teacher with a passion for igniting spiritual revival and awakening.
                                  His teachings are characterized by a profound understanding of the mysteries of the
                                  Kingdom and a fervent call to deepen one&apos;s relationship with God. With a heart for prayer,
                                  intercession, and preparing the Church for the end times, Apostle Arome&apos;s ministry has touched
                                  countless lives around the world. His focus in the time and age is on the rebirth of apostolic
                                  Christianity in our world.
                                </p>
                                <div className="apostolic-invasion-flex">
                                  <div className="apostolic-invasion-details">
                                    <p className="general-bold">Event Details:</p>
                                    <ul className="margin-left-event apostolic-invasion-list">
                                      <li><b>Date:</b> Friday 8th – Sunday 10th September, 2023</li>
                                      <li><b>Venue:</b> Grace Definition Church, Ijebu Ode, Ogun State</li>
                                      <li><b>Guest Speakers:</b> Apostle Arome Osayi and other anointed minsters of God</li>
                                      <li><b>Host:</b> Pastor James Oluwasanni</li>
                                    </ul>
                                  </div>
                                  <div className="apostolic-invasion-schedule">
                                    <p className="general-bold event-schedule-sm">Event Schedule:</p>
                                    <p><b>• Friday, September 8th:</b></p>
                                    <p className="margin-left-event">• Evening Session: 4:00 PM</p>
                                    <p><b>• Saturday, September 9th:</b></p>
                                    <p className="margin-left-event">• Morning Session: 8:00 AM</p>
                                    <p className="margin-left-event">• Afternoon Session: 4:00 PM </p>
                                    <p className="general-bold">• Sunday, September 10th:</p>
                                    <p className="margin-left-event">• Morning Session: 8:00 AM</p>
                                  </div>
                                </div>
                                <p>Don&apos;t miss the opportunity to be part of this extraordinary gathering where Apostle Arome Osayi
                                  will be sharing deep insights into prayer, intercession, spiritual warfare, and the apostolic
                                  calling. Prepare to be inspired, challenged, and equipped to walk in a greater dimension of your
                                  faith journey.
                                </p>
                                <div>
                                  <p className="general-bold">Enquiries - if you need help with logistics/hostel reservation, kindly contact any of the following brethren:</p>
                                  <p>Pastor Jide: +4407377219457</p>
                                  <p>Pastor Essien: +2347055662406</p>
                                  <p>Jennifer: +2348130916417</p>
                                  <p>Kayode: +2349059579557</p>
                                  <p>Tunde: +2348034773199</p>
                                </div>
                                <div>
                                  <p><b>Volunteering:</b> We desire that you partner with us to make the &rsquo;Apostolic Invasion&rsquo; program a success.
                                    If you&apos;re interested in volunteering and contributing your time and skills, we welcome your
                                    support. Whether it&apos;s helping with event coordination, hospitality, or technical support, your
                                    contribution matters. To volunteer, please contact the following brethren or click on the volunteer form to fill the form <a style={{color:'blue'}} href="https://forms.gle/SiD9HwCZZye5irbg7" target="_blank" rel="noreferrer" alt="volunteer form">volunteer form</a> 
                                  </p>
                                  <p>Rev Gideon: +2347017296503</p>
                                  <p>Pastor Jide: +4407377219457</p>
                                  <p>Pastor Essien: 07055662406</p>
                                </div>
                                <p>We look forward to receiving you!</p>
                                <p>For more information and updates, follow us on social media.</p>
                                <p>Facebook: <a href="">Grace Definition Church</a> </p>
                                <p>Instagram: <a href="">Grace Definition Church</a></p>
                              </div>
                            ) :(
                                <p>{event && event.description}</p>
                            )}
                        </article>
                    </div>

                    <div className="col-md-3">
                        <div className="highlight-bg has-padding event-details has-margin-xs-bottom">
                            <div className="ed-title">EVENT DETAILS</div>
                            <div className="ed-content">
                                <span className="glyphicon glyphicon-calendar">
                                </span>
                                {event && event.date} <br />
                                <span className="glyphicon glyphicon-time"></span> {event && formatTime(event.start_time)}
                                <span>&nbsp; - &nbsp; {event && formatTime(event.end_time)}</span> <br />
                                <span className="glyphicon glyphicon-map-marker"></span> {event && event.location}</div>

                        </div>
                        <div className="vertical-links has-margin-xs-bottom has-padding event-details has-margin-xs-bottom">
                            <h4>Event Categories</h4>
                            <ol className="list-unstyled">
                                {
                                    event && event.categories.map((item, i) =>
                                        <li key={i}><a href="#">{item.name} <span className="badge pull-right">{item.events_count}</span>
                                        </a></li>
                                    )
                                }
                            </ol>
                        </div>
                        <div className="tag-cloud has-margin-bottom">
                            {
                                event && event.tag_data.map((item, i) =>
                                    <a href="#" key={i}>{item.name}</a>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SingleEventPage

