import { createContext, useCallback, useState } from 'react'
import { useImmer } from 'use-immer';
import data from './data.js';

export const DatabaseContext = createContext();




function Database( { routes } ){
    
    let [ currentPage , setCurrentPage ] = useState("/")
    let [ PastorsData, updatePastorsData] = useImmer(data.pastorsData)
    let [ AllPastorPages, updateAllPastorPages] = useImmer(data.AllPastorPages)
    let [ blogsDataList, updateBlogsDataList] = useImmer(data.blogData)
    let [ sermonList , SetSermonList ] = useState(data.sermonList)
    let [upCommingEventList, setUpCommingEventList] = useState(data.upCommingEvents)
    let [gdcWatch, setGdcWatch] = useState(data.gdc)
    let [liveSermon, setLiveSermon] = useState(data.liveSermon)
    let [audioSermon, setAudioSermon] = useState(data.audioSermon)
    let [videoSermon, setVideoSermon] = useState(data.videoSermon)
    let [ministryData, SetministryData] = useState(data.ministryData)

    // change page
    var goToPage = useCallback((navigate, link) => {
        setCurrentPage(()=> link)
        navigate(link)
    }, [])



    return (
        <DatabaseContext.Provider value={ {
            currentPage, goToPage,
            PastorsData, updatePastorsData,
            AllPastorPages, updateAllPastorPages,
            blogsDataList, updateBlogsDataList,
            sermonList , SetSermonList,
            upCommingEventList, setUpCommingEventList,
            gdcWatch, setGdcWatch,
            liveSermon, setLiveSermon,
            audioSermon, setAudioSermon,
            videoSermon, setVideoSermon,
            ministryData, SetministryData

        } }>
            { routes }
        </DatabaseContext.Provider>
    )
}

export default Database