import { useContext } from "react";
import { DatabaseContext } from "../../Database/Database";
import { Link } from "react-router-dom";

function BlogSermon() {
    let { blogsDataList, sermonList } = useContext(DatabaseContext)
  


    return ( <>
    {/*Blog section and sermon flex start here*/}
<div className="container has-margin-bottom" style={{marginTop:"50px"}}>
  <div className="row">
    <div className="col-md-9 has-margin-bottom">
      <div className="section-title left-align-desktop">
        <h4> ARTICLE </h4>
      </div>
        { blogsDataList.slice(0,2).map((value, index) => {
            return <div key={`${index}`} className={"row " + (index === 0 ? "has-margin-bottom" : "")}>
            <div className="col-md-4 col-sm-4"> 
              <img className="img-responsive center-block" src={value.src} alt="bulletin blog" /> 
            </div>
            <div className="col-md-8 col-sm-8 bulletin">
              <h4 className="media-heading">{value.title} </h4>
              <p>{value.date} by <a href="#" className="link-reverse"></a>{value.preacher}</p>
              <p className="media-content">{value.body}</p>
              <a className="btn btn-primary" href="#" role="button">Read more →</a> 
            </div>
          </div>
        })}
    </div>

    
    <div className="col-md-3">
      <div className="well">
        <div className="section-title">
          <h4 className="section-title-sermons"> RECENT SERMONS </h4>
        </div>
        <Link to="/sermons"><img src="./images/thumbnail 001.jpg" className="img-responsive center-block" alt="video thumb" /></Link>
        <div className="list-group"> 
            { sermonList.slice(0, 3).map( (value, index) => 
            <a key={`${index}`} href={value.url} className="list-group-item">
              <p className="list-group-item-heading">{value.title}</p>
              <p className="list-group-item-text">{value.duration}</p>
            </a> ) }
        </div>
      </div>
    </div>
    
  </div>
</div>
{/*Blog section and sermon flex ends here*/}

    </> );
}

export default BlogSermon;

