import { useContext } from "react";
import { DatabaseContext } from "../../Database/Database";
import { Link } from "react-router-dom";

export function Upcoming() {
    return <> 
    <div className="mb-5">

    </div>
    <div className="highlight-bg has-margin-bottom">
      <div className="container event-cta">
        <div className="ec-txt"> <span>UPCOMING EVENT</span>
          <p>&quot;The Apostolic Invasion&quot; - Apostle Osayi Arome</p>
        </div>
        <Link className="btn btn-lg btn-primary" to="/event-page" role="button">Program details →</Link> 
      </div>
    </div>
   </>
}

export function Donate() {
    return <div className="container has-margin-bottom donate-change-color">
    <div className="section-title">
        <h4>DONATE </h4>
    </div>
    <div className="charity-box">
        <div className="charity-image"> 
        <img src="./images/donate.jpg" className="img-responsive" alt="charity donation fundraising" />
        </div>
        <div className="charity-desc">
        <h4>Make a Difference. Support our mission. Donate today. </h4>
        <p className="charity-desc-text"> Your generosity empowers us to serve, inspire, and bring hope to individuals and communities. 
            Join us in making a lasting impact through your donation. Thank you for your support
        </p>
        <div className="donate-renew">
            <Link to="/donation" className="btn btn-lg btn-primary">Donate Now →</Link>
        </div>
        </div>
    </div>
    </div>

}

export function Gallery() {
    return <div className="container has-margin-bottom">
  <div className="section-title">
    <h4> OUR GALLERY </h4>
  </div>
  <div className="img-gallery row">
    <div className="col-sm-4 col-md-3"> <a className="fancybox" href="./images/church_gallery/foto/DSC_6293.png" data-fancybox-group="gallery" title="church image gallery"> <img src="./images/church_gallery/a.jpg" className="img-responsive" width={270} height={270} alt="church image gallery" /> </a> </div>
    <div className="col-sm-4 col-md-3"> <a className="fancybox" href="./images/church_gallery/foto/DSC_6249.png" data-fancybox-group="gallery" title="church image gallery"> <img src="./images/church_gallery/b.jpg" className="img-responsive" width={270} height={270} alt="church image gallery" /> </a> </div>
    <div className="col-sm-4 col-md-3"> <a className="fancybox" href="./images/church_gallery/foto/DSC_4417.png" data-fancybox-group="gallery" title="church image gallery"> <img src="./images/church_gallery/DSC_4417.png" className="img-responsive" width={270} height={270} alt="church image gallery" /> </a> </div>
    <div className="col-sm-4 col-md-3"> <a className="fancybox" href="./images/church_gallery/foto/DSC_4418.png" data-fancybox-group="gallery" title="church image gallery"> <img src="./images/church_gallery/DSC_4418.png" className="img-responsive" width={270} height={270} alt="church image gallery" /> </a> </div>
    <div className="col-sm-4 col-md-3"> <a className="fancybox" href="./images/church_gallery/foto/DSC_4420.png" data-fancybox-group="gallery" title="church image gallery"> <img src="./images/church_gallery/DSC_4420.png" className="img-responsive" width={270} height={270} alt="church image gallery" /> </a> </div>
    <div className="col-sm-4 col-md-3"> <a className="fancybox" href="./images/church_gallery/foto/DSC_6263.png" data-fancybox-group="gallery" title="church image gallery"> <img src="./images/church_gallery/c.jpg" className="img-responsive" width={270} height={270} alt="church image gallery" /> </a> </div>
    <div className="col-sm-4 col-md-3"> <a className="fancybox" href="./images/church_gallery/foto/DSC_6289.png" data-fancybox-group="gallery" title="church image gallery"> <img src="./images/church_gallery/d.jpg" className="img-responsive" width={270} height={270} alt="church image gallery" /> </a> </div>
    <div className="col-sm-4 col-md-3"> <a className="fancybox" href="./images/church_gallery/foto/DSC_4427.png" data-fancybox-group="gallery" title="church image gallery"> <img src="./images/church_gallery/DSC_4427.png" className="img-responsive" width={270} height={270} alt="church image gallery" /> </a> </div>
  </div>
  <div className="gallery-button">
    <p><a className="btn btn-lg btn-primary" href="#" role="button">See more</a></p>
  </div>
</div>

}

export function GDCWatch() {
    let { gdcWatch } = useContext(DatabaseContext)
    return   <div className="highlight-bg has-margin-bottom">
  <div className="container event-list">
    <div className="section-title">
      <h4> GDC WATCH </h4>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div >
          <div className="item">
            <blockquote className="blockquote-centered"> {gdcWatch.text}<small>{gdcWatch.passage}</small> </blockquote>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

}

export function Ministries() {
    return   <div className="container">
  <div className="section-title">
    <h4> OUR MINISTRIES </h4>
  </div>
  <div className="row feature-block">
    <div className="col-md-4 col-sm-6 has-margin-bottom"> <img className="img-responsive" src="/images/children.png" alt="catholic church" />
      <h5>CHILDREN UNIT</h5>
      <p>We&lsquo;re thrilled to invite all the little adventurers to our fun-filled world of joy and learning. 
        At our Children&lsquo;s Department, kids get to explore exciting activities that spark their imagination and nurture their faith.... 
      </p>
      <p><Link to="/ministries" role="button">Visit →</Link></p>
    </div>
    <div className="col-md-4 col-sm-6 has-margin-bottom"> <img className="img-responsive" src="/images/technical.png" alt="ministry sermon" />
      <h5>TECHNICAL/SOUND UNIT</h5>
      <p>At Grace Definition Church, we understand the power of technology and sound in enhancing our worship experiences and creating 
        a vibrant atmosphere for spiritual growth...
      </p>
      <p><Link to="/ministries" role="button">Visit →</Link></p>
    </div>
    <div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0 center-this has-margin-bottom"> <img className="img-responsive" src="/images/ushering.png" alt="bulletin programs" />
      <h5>USHERING/SANCTUARY KEEPERS UNIT</h5>
      <p>We believe that the first step to fostering a warm and inviting church community begins with a friendly smile and a helping hand. 
        Our Ushering Department takes great pride in ensuring that every individual who walks....</p>
      <p><Link to="/ministries" role="button">Visit →</Link></p>
    </div>
  </div>
</div>

}

