import { Link } from "react-router-dom"

function OurPastorPage() {
    return (
        <section className="paddingtop">
            <div className="container">
                <div className="pastorjames-details">
                    <div className="pastorjames-details-name">
                        <p>Pastor <br /> James Awosannmi</p>
                        <div className="pastorjames-details-name-logo"></div>
                    </div>
                    <div className="pastorjames-details-bg">
                        <div className="pastorjames-details-bg-pic">

                        </div>
                    </div>
                </div>
                <div className="pastorjames-profile">
                    <p>
                        Pastor James Oluwasanmi is characterized by a
                        genuine desire to connect with individuals from all walks of life. With a compassionate approach,
                        he leads us in exploring
                        the teachings of the Bible and understanding how they relate to our everyday challenges and joys.
                    </p>
                    {/* <p>
                        Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aenean eu leo quam.
                        Pellentesque ornare sem lacinia quam venenatis vestibulum. Sed posuere consectetur est at lobortis. Cras
                        mattis consectetur purus sit amet fermentum. Curabitur blandit tempus porttitor. Nullam quis risus eget
                        urna mollis ornare vel eu leo. Nullam id dolor id nibh ultricies vehicula ut id elit.
                    </p>
                    <p>
                        Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean
                        lacinia bibendum nulla sed consectetur Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                        Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
                    </p> */}
                </div>
                <div className="pastorjames-button">
                    <Link className="btn btn-primary" to="/all-pastors">BACK TO PASTOR</Link>
                </div>
            </div>
        </section>
    )
}

export default OurPastorPage
