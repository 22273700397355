import { useState, useEffect } from 'react';
import { appURL } from '../../env';
import axios from 'axios';
import { useAuth } from '../../hooks/useAuthContext';
import LoadingPage from '../../components/Utilities/Loading';

function Hero() {
  const { user } = useAuth();
  const token = user ? user.token : null;

  const [banners, bannerSlider] = useState([]);
  const [bannerLoading, setBannerLoading] = useState(false)

  useEffect(() => {
    // Fetch data when the component mounts
    // setBannerLoading(true)
    axios.get(`${appURL}/background-slider/fetchBanners`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })

      .then((res) => {
        console.log(res.data.data);
        // Update the state with the fetched data
        bannerSlider(res.data.data);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        // setBannerLoading(false)
        // Handle the error
        // You can show an error message to the user here
      })
      .finally(() => setBannerLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  return (
    bannerLoading ?
      <LoadingPage></LoadingPage>
      :
      <>
        {banners && banners.length > 0 && (

          <div id="myCarousel" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
              {banners.map((_, index) => (
                <li
                  key={index}
                  data-target="#myCarousel"
                  data-slide-to={index}
                  className={index === 0 ? 'active' : ''}
                />
              ))}
            </ol>

            <div className="carousel-inner">
              {banners.map((banner, index) => (
                <div
                  key={banner.id}
                  className={`item slide-one ${index === 0 ? 'active' : ''}`}

                >
                  <img
                    src={banner.slider_image}
                    alt={`Banner ${index + 1}`}
                    className="img-responsive has-margin-xs-bottom"
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'block',
                      margin: '0 auto',
                     
                    }}
                  />
                  <div className="container">
                    <div className="carousel-caption">
                      <h2>{banner.slider_text}</h2>
                      <p className="lead"><a className="btn btn-lg btn-primary" href="#" role="button">{banner.link} →</a></p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <a
              className="left carousel-control"
              href="#myCarousel"
              role="button"
              data-slide="prev"
            >
              <span className="glyphicon glyphicon-chevron-left" />
            </a>
            <a
              className="right carousel-control"
              href="#myCarousel"
              role="button"
              data-slide="next"
            >
              <span className="glyphicon glyphicon-chevron-right" />
            </a>
          </div>

        )}
      </>
  );
}

export default Hero;
