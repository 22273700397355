import { Outlet, useLocation } from "react-router-dom";

function Layout() {
  var location = useLocation()

  return (
    <>
      <div className="navbar navbar-default navbar-fixed-top" role="navigation">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>
            </button>
            <a className="navbar-brand" href="/">
              <img
                src="/images/GDC_logo2.png"
                alt="church logo"
                className="img-responsive"
              />
            </a>
          </div>
          <div className="navbar-collapse collapse">
            <ul className="nav navbar-nav navbar-right">
              <li className={ location.pathname  == "/" ?"active" : ""}>
                <a href="/">
                  HOME 
                </a>
              </li>
              <li className={ location.pathname  == "/about" ?"active" : ""}>
                <a href="/about">ABOUT</a>
              </li>

              <li className={  location.pathname  == "/ministries" ?"active" : ""} ><a href="/ministries">MINISTRY</a></li>
              <li className={location.pathname  == "/sermons" ? "active" : ""} >
                <a  
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                >
                  SERMONS <span className="caret"></span>
                </a>
                <ul className="dropdown-menu dropdown-menu-left" role="menu">
                  <li><a href="#">Audio</a></li>  {/* fake url  */}
                  {/* <li><a href="/sermons">Stream Live</a></li> */}
                  {/* <li><a href="/sermons?audio">Audio Sermons</a></li> */}
                  {/* <li><a href="/sermons?video">Video Sermons</a></li> */}
                  {/* <li><a href="/sermons/video">Video Sermons</a></li> */}
                </ul>
              </li>

              <li className={ location.pathname  == "/contact" || location.pathname  == "/prayers"  || location.pathname  == "/testimony"   ? "active" : ""} >
                
                  <a  className="dropdown-toggle" data-toggle="dropdown">RESOURCES <span className="caret" /></a>
                  <ul className="dropdown-menu dropdown-menu-left" role="menu">
                    <li><a href="/contact">Contact</a></li>
                    <li><a href="/prayers">Prayer Request</a></li>
                    <li><a href="/testimony">Testimony</a></li>
                  </ul>


              </li>
              <li className="donate-nav">
                <a href="/donation">Donate</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Outlet />
      <NewsLetter />
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-3">
              <h5>ABOUT US</h5>
              <p>
                We are a church set to raise a generation that is delivered from
                the power of darkness, taught in the word of God; Who with the
                influence of the word make a difference in their world.
              </p>
            </div>
            <div className="col-sm-6 col-md-3">
              <h5>QUICK LINKS</h5>
              <ul className="footer-links">
                <li>
                  <a href="#">Upcoming events</a>
                </li>
                <li>
                  <a href="#">Ministries</a>
                </li>
                <li>
                  <a href="#">Recent Sermons</a>
                </li>
                <li>
                  <a href="#">Contact us</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-3">
              <h5>OUR ADDRESS</h5>
              <p>
                {" "}
                GRACE DEFINITION CHURCH
                <br />
                By Shilo Arena Junction Adjacent <br />
                AM 2PM Hotel Molipa Express,
                <br />
                Ijebu-Ode, ogun State, Nigeria
                <br />
                Phone: +234 7055662406
                <br />
                Email:{" "}
                <a href="#">
                  <span
                    className="__cf_email__"
                    data-cfemail="432e222a2f032022372b2c2f2a20342621302a37266d202c2e"
                  >
                    gdc@gracedefinitionchurch.com
                  </span>
                </a>
              </p>
            </div>
            <div className="col-sm-6 col-md-3">
              <h5>CONNECT</h5>
              <div className="social-icons">
                <a href="https://m.facebook.com/GraceDefinitionChurch">
                  <i className="fa-brands fa-facebook"></i>
                </a>
                <a href="https://m.youtube.com/channel/UCJbBnMKMEn90KUABECdI1cA">
                  <i className="fa-brands fa-youtube rounded-circle"></i>
                </a>
                <a href="https://twitter.com/Gracedefinition">
                  <i className="fa-brands fa-twitter rounded-circle"></i>
                </a>
                <a href="https://www.instagram.com/gracedefinitionchurch1/">
                  <i className="fa-brands fa-instagram rounded-circle"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="container">
            <p className="text-center">Copyright © 2023 All rights reserved</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Layout;


function NewsLetter() {
  return    <div className="highlight-bg">
<div className="container">
  <div className="row">
    <form action="#" method="post" className="form subscribe-form" role="form" id="subscribeForm">
      <div className="form-group col-md-3 hidden-sm">
        <h5 className="susbcribe-head"> SUBSCRIBE <span>TO OUR NEWSLETTER</span></h5>
      </div>
      <div className="form-group col-sm-8 col-md-6">
        <label className="sr-only">Email address</label>
        <input type="email" className="form-control input-lg" placeholder="Enter email" name="email" id="address" data-validate="validate(required, email)" required />
        <span className="help-block" id="result" /> 
      </div>
      <div className="form-group col-sm-4 col-md-3">
        <button type="submit" className="btn btn-lg btn-primary btn-block">Subscribe Now →</button>
      </div>
    </form>
  </div>
</div>
</div>

}