import { useLocation } from "react-router-dom";
export default function Ministries ()  {
  var location = useLocation()
    return (
        <>


        {
          location.pathname == "/contact" ? 
          <div className="subpage-head has-margin-bottom contact-section contact_section"> 
            <div className="container">
              {/* <h3>Contact us</h3> */} 
            </div>
          </div> :
                  
        <div className="subpage-head has-margin-bottom contact-section sermons-section"> 
          { location.pathname == "/prayers" ? <div className="container">
            <h3>Prayer Request</h3> 
            <p className="lead">Unite in prayer for hope, healing, and love to reign in our lives and beyond!</p>
          </div>:
          <div className="container">
            <h3>Testimony</h3> 
            <p className="lead">Share your testimony of God&rsquo;s goodness and grace, and let your story inspire others to experience His life-changing power in their lives too!</p>
          </div>          
        }
        </div>



        }


          
    {/*Form and address section start here*/}
    <div className="container">
      <div className="row">
        <div className="col-md-6 has-margin-bottom">
          <form id="phpcontactform" role="form">
            <div className="form-group">
              <label>Full Name</label>
              <input type="text" className="form-control" name="name" id="name" />
            </div>
            <div className="form-group">
              <label>Email ID</label>
              <input type="email" className="form-control" name="email" id="email" />
            </div>
            <div className="form-group">
              <label>Phone</label>
              <input type="text" className="form-control" name="mobile" id="mobile" />
            </div>
            <div className="form-group">
              <label>Message</label>
              <textarea className="form-control" rows={5} name="message" id="message" placeholder={ location.pathname == "/contact" ?  "Contact us" : ( location.pathname == "/prayers" ? "Prayer request" : "Your testimony" )} />
            </div>
            <button type="submit" className="btn btn-primary btn-lg">Send message</button>
            <span className="help-block loading" />
          </form>
        </div>
        <div className="col-md-6 has-margin-bottom contact-church-address">
          <h5>OUR ADDRESS</h5>
          <div className="row">
            <div className="col-lg-6"> By Shilo Arena Junction Adjacent<br />
            AM 2PM Hotel Molipa Express, <br />
            Ijebu-Ode, ogun State, Nigeria
            </div>
            <div className="col-lg-6">Phone: +234 7055662406<br />
              {/* Email: <a href="#"><span className="__cf_email__" data-cfemail="630e020a0f230002170b0c0f0a00140601100a17064d000c0e">[email&nbsp;protected]</span></a> */}
            </div>
          </div>
          <hr className="contact-hr" />
          <h5>OUR ADDRESS</h5>
          {/* <div className="row">
            <div className="col-lg-6">54, Bonojo<br />
              beside Blooms pharmacy, <br />
              Ijebu-Ode, Ogun State
            </div>
            <div className="col-lg-6">Phone: +61 3 8376 6284<br />
              Fax: +61 38 376 6284<br />
              Email: <a href="#"><span className="__cf_email__" data-cfemail="adc0ccc4c1edceccd9c5c2c1c4cedac8cfdec4d9c883cec2c0">[email&nbsp;protected]</span></a>
            </div>
          </div> */}
          <div className="social-icons contact-social-icons">
            <a href="https://m.facebook.com/GraceDefinitionChurch" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-facebook" /></a> 
            <a href="https://m.youtube.com/channel/UCJbBnMKMEn90KUABECdI1cA" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-youtube rounded-circle" /></a> 
            {/* <a href="#" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin rounded-circle" /></a>  */}
            <a href="https://twitter.com/Gracedefinition" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-twitter rounded-circle" /></a>
            <a href="https://www.instagram.com/gracedefinitionchurch1/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-instagram rounded-circle" /></a>
          </div>
        </div>
      </div>
    </div>
    {/*Form and address section ends here*/}

        </>
    );
}