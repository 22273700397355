// import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import { AuthContextProvider } from './context/AuthContext.js';
import './index.css';

ReactDOM.createRoot(document.getElementById('root')).render(
 
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  
);

// ReactDOM.render(
//     <App />
//   document.getElementById('root')
// );
