import { Link } from "react-router-dom";



export default function OurPastor() {

  return (

    <section className="ourpastors">
      <div className="container">
        <div className="meet_ourpastors">
          <div className="meet_ourpastors-details">
            <p className="meet_ourpastors-details-header">MEET OUR <br /><span>Pastors</span></p>
            <p className="meet_ourpastors-details-text">Pastor James Oluwasanmi is characterized by a 
            genuine desire to connect with individuals from all walks of life. With a compassionate approach, 
            he leads us in exploring 
            the teachings of the Bible and understanding how they relate to our everyday challenges and joys. 
            </p>
            <p className="meet_ourpastors-details-links">
              <Link to="/our-pastor" className="meet_ourpastors-details-links-one">READ MORE</Link>
              <Link to="/all-pastors" className="meet_ourpastors-details-links-two" >MEET ALL PASTORS</Link>
            </p>
          </div>
        </div>
      </div>
    </section>

    // <section className="paddingtop">
    //   <div className="container">
    //     <div className="pastorjames-details">
    //       <div className="pastorjames-details-name">
    //         <p>Pastor. <br /> James Awosannmi</p>
    //         <div className="pastorjames-details-name-logo"></div>
    //       </div>
    //       <div className="pastorjames-details-bg">
    //         <div className="pastorjames-details-bg-pic">

    //         </div>
    //       </div>
    //     </div>
    //     <div className="pastorjames-profile">
    //       <p>
    //       Pastor Jame Oluwasanmi is characterized by a 
    //        genuine desire to connect with individuals from all walks of life. With a compassionate approach, 
    //        he leads us in exploring 
    //        the teachings of the Bible and understanding how they relate to our everyday challenges and joys.
    //       </p>
    //       {/* <p>
    //         Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aenean eu leo quam.
    //         Pellentesque ornare sem lacinia quam venenatis vestibulum. Sed posuere consectetur est at lobortis. Cras
    //         mattis consectetur purus sit amet fermentum. Curabitur blandit tempus porttitor. Nullam quis risus eget
    //         urna mollis ornare vel eu leo. Nullam id dolor id nibh ultricies vehicula ut id elit.
    //       </p> */}
    //       {/* <p>
    //         Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean
    //         lacinia bibendum nulla sed consectetur Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
    //         Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
    //       </p> */}
    //     </div>
    //     <div className="pastorjames-button">
    //       <a className="btn btn-primary" href="pastors.html">BACK TO PASTORS</a>
    //     </div>
    //   </div>
    // </section>
  );
}