import { Link } from "react-router-dom"

function EventPage() {
  return (
    <div className="container general-margin livesermons-section">
      <div className="row">
        <div className="col-md-9 has-margin-bottom">

          
          <div className="embed-responsive embed-responsive-16by9 has-margin-xs-bottom">
            <iframe
              className="embed-responsive-item"
              src="/images/VID-20230825-WA0009.mp4"
              width="620"
              height="349"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <div className="apostolic-invasion-section">
            <p className="general-bold apostolic-invasion-title">Apostolic Invasion: A 3-Day Encounter with Apostle Arome Osayi</p>
            <p>Welcome to the <b>&rsquo;Apostolic Invasion&rsquo;</b> program hosted by Grace Definition Church in Ijebu Ode,
              Ogun State. Get ready for an incredible three days of transformative teachings, fervent worship, and spiritual empowerment as we welcome the esteemed Apostle Arome Osayi. This event promises to be a life-changing experience
              as we delve into the depths of God&apos;s Word, seeking a fresh encounter with His presence.
            </p>
            <p><b>About Apostle Arome Osayi:</b> Apostle Arome Osayi is a renowned Christian minister,
              speaker, and teacher with a passion for igniting spiritual revival and awakening.
              His teachings are characterized by a profound understanding of the mysteries of the
              Kingdom and a fervent call to deepen one&apos;s relationship with God. With a heart for prayer,
              intercession, and preparing the Church for the end times, Apostle Arome&apos;s ministry has touched
              countless lives around the world. His focus in the time and age is on the rebirth of apostolic
              Christianity in our world.
            </p>
            <div className="apostolic-invasion-flex">
              <div className="apostolic-invasion-details">
                <p className="general-bold">Event Details:</p>
                <ul className="margin-left-event apostolic-invasion-list">
                  <li><b>Date:</b> Friday 8th – Sunday 10th September, 2023</li>
                  <li><b>Venue:</b> Grace Definition Church, Ijebu Ode, Ogun State</li>
                  <li><b>Guest Speakers:</b> Apostle Arome Osayi and other anointed minsters of God</li>
                  <li><b>Host:</b> Pastor James Oluwasanni</li>
                </ul>
              </div>
              <div className="apostolic-invasion-schedule">
                <p className="general-bold event-schedule-sm">Event Schedule:</p>
                <p><b>• Friday, September 8th:</b></p>
                <p className="margin-left-event">• Evening Session: 4:00 PM</p>
                <p><b>• Saturday, September 9th:</b></p>
                <p className="margin-left-event">• Morning Session: 8:00 AM</p>
                <p className="margin-left-event">• Afternoon Session: 4:00 PM </p>
                <p className="general-bold">• Sunday, September 10th:</p>
                <p className="margin-left-event">• Morning Session: 8:00 AM</p>
              </div>
            </div>
            <p>Don&apos;t miss the opportunity to be part of this extraordinary gathering where Apostle Arome Osayi
              will be sharing deep insights into prayer, intercession, spiritual warfare, and the apostolic
              calling. Prepare to be inspired, challenged, and equipped to walk in a greater dimension of your
              faith journey.
            </p>
            <div>
              <p className="general-bold">Enquiries - if you need help with logistics/hostel reservation, kindly contact any of the following brethren:</p>
              <p>Pastor Jide: +4407377219457</p>
              <p>Pastor Essien: +2347055662406</p>
              <p>Jennifer: +2348130916417</p>
              <p>Kayode: +2349059579557</p>
              <p>Tunde: +2348034773199</p>
            </div>
            <div>
              <p><b>Volunteering:</b> We desire that you partner with us to make the &rsquo;Apostolic Invasion&rsquo; program a success.
                If you&apos;re interested in volunteering and contributing your time and skills, we welcome your
                support. Whether it&apos;s helping with event coordination, hospitality, or technical support, your
                contribution matters. To volunteer, please contact the following brethren or click on the volunteer form to fill the form <a style={{color:'blue'}} href="https://forms.gle/SiD9HwCZZye5irbg7" target="_blank" rel="noreferrer" alt="volunteer form">volunteer form</a> 
              </p>
              <p>Rev Gideon: +2347017296503</p>
              <p>Pastor Jide: +4407377219457</p>
              <p>Pastor Essien: 07055662406</p>
            </div>
            <p>We look forward to receiving you!</p>
            <p>For more information and updates, follow us on social media.</p>
            <p>Facebook: <a href="https://m.facebook.com/GraceDefinitionChurch">Grace Definition Church</a> </p>
            <p>Instagram: <a href="https://www.instagram.com/gracedefinitionchurch1/">Grace Definition Church</a></p>
          </div>
        </div>


        <div className="col-md-3 about-us-upcoming-event">
          <div className="section-title highlight-bg has-padding-xs-top has-padding-bottom about-us-heading-row">
            <h4> DONATE </h4>
            <p className="upcoming-event-donate">Support the Apostolic Invasion program and
              impact lives for Christ. Your donation helps us bring powerful teachings
              and transformative worship to our community. Give today for a lasting impact!
            </p>
            <Link to="/donation" className="btn btn-sm btn-primary">Donate Now →</Link>
          </div>

          {/* <div className="el-block has-margin-xs-bottom">
              <h4> AUGUST 2-4 </h4>
              <p className="el-head">Kids church camp</p>
              <span>Wednesday 08:00 AM</span>
              <p className="el-cta"><a className="btn btn-primary" href="#" role="button">Details →</a></p>
            </div>
            <div className="el-block ">
              <h4> JUL 29 </h4>
              <p className="el-head">Show me your faith</p>
              <span>Thursday 02:00 PM</span>
              <p className="el-cta"><a className="btn btn-primary" href="#" role="button">Details →</a></p>
            </div> */}
        </div>
      </div>
    </div>
  )
}

export default EventPage
