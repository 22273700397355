import { useState } from "react";

export function Ministry() {
  let [ministriesList] = useState([{}, {}, {}, {}, {}, {}])
  return (
    <>
      <div className="subpage-head has-margin-bottom contact-section ministry-section" />



      <div className="container">

        <div className="row feature-block">
          {ministriesList && ministriesList.map((value, i) => <div key={`ministries-${i}`} className="col-md-4 col-sm-6 has-margin-bottom"> <img className="img-responsive" src="./images/children.png" alt="catholic church" />
            <h5>CHILREN UNIT</h5>
            <p>We&apos;re thrilled to invite all the little adventurers to our fun-filled world of joy and learning.
              At our Children&apos;s Department, kids get to explore exciting activities that spark their imagination and nurture their faith....
            </p>
            <p><a href="#" role="button">Read more →</a></p>
        </div> )}


        </div>


      </div>

    </>
  );
}