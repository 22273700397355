
import { useEffect } from 'react';
// import { readMoreText } from './scriptMinistry';
function AllministryPage() {

    useEffect(() => {
        document.getElementsByName("readMore").forEach((value, i) => {
          value.setAttribute("onclick", `readMoreText(${i})`);
        });
      }, []);
    
    
    return (
        <div className="container">

            <div className="row feature-block">
                <div className="col-md-4 col-sm-6 has-margin-bottom"> <img className="img-responsive" src="./images/children.png" alt="catholic church"/>
                    <h5>CHILDREN UNIT</h5>
                    <p>We&apos;re thrilled to invite all the little adventurers to our fun-filled world of joy and learning.
                        At our Children&apos;s Department, kids get to explore exciting activities that spark their imagination and nurture their faith<span name="dots" id="dots">....</span>
                        <span name="more" id="more">Here, we believe that every child is unique and special. Our team of dedicated and caring teachers ensures a
                            safe and welcoming environment where kids can grow in their understanding of God&apos;s love and teachings. Together, we&apos;ll explore, learn, and grow in the love of God.</span>
                    </p>
                    <p><button type="button" className="btn btn-success btn-sm readbtn-minstry" role="button" name="readMore" id="readMore">Read more →</button></p>
                </div>

                <div className="col-md-4 col-sm-6 has-margin-bottom"> <img className="img-responsive" src="./images/technical.png" alt="ministry sermon"/>
                    <h5>TECHNICAL/SOUND UNIT</h5>
                    <p>At Grace Definition Church, we understand the power of technology and sound in enhancing our worship experiences and creating
                        a vibrant atmosphere for spiritual growth<span name="dots" id="dots" >...</span>
                        <span name="more" id="more">. Our Technical and Sound Department is dedicated to delivering top-notch audiovisual support to
                            all our events and services. Our Technical and Sound Department is an essential pillar of our worship experience,
                            working diligently behind the scenes to uplift and inspire. We invite tech enthusiasts and audio
                            aficionados to join us in serving the church community through our passion for technology and sound. Let&apos;s create
                            an impactful worship experience together!</span>
                    </p>
                    <p><button type="button" className="btn btn-success btn-sm readbtn-minstry" role="button" name="readMore" id="readMore">Read more →</button></p>
                </div>

                <div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0 center-this has-margin-bottom"> 
                    <img className="img-responsive" src="/images/ushering.png" alt="bulletin programs"/>
                    <h5>USHERING/SANCTUARY KEEPERS UNIT</h5>
                    <p>We believe that the first step to fostering a warm and inviting church community begins with a friendly smile and a helping hand.
                        Our Ushering Department takes great pride in ensuring that every individual who walks<span name="dots" id="dots">....</span>
                        <span name="more" id="more">through our doors feels embraced and valued. From the moment you arrive,
                            our team of ushers will greet you with a genuine smile and a helping hand. Whether you need assistance
                            finding a seat, information about our events, or have any questions, our ushers are here to ensure your
                            visit is comfortable and enjoyable. Join us at the Ushering Department and be a part of spreading the love
                            and warmth that defines our church community. Together, we can make a difference, one smile at a time.</span>
                    </p>
                    <p><button type="button" className="btn btn-success btn-sm readbtn-minstry" role="button" name="readMore" id="readMore">Read more →</button></p>
                </div>

                <div className="col-md-4 col-sm-6 has-margin-bottom"> 
                    <img className="img-responsive" src="/images/quality_control.png" alt="catholic church"/>
                    <h5>QUALITY CONTROL</h5>
                    <p>At Grace Definition Church, we are committed to delivering excellence in all aspects of our ministry.
                        Our Quality Control Department plays a pivotal role in ensuring that every service, event,
                        and activity <span name="dots" id="dots">....</span>
                        <span name="more" id="more">meets the highest standards of quality and integrity. Led by a
                            dedicated team, this department diligently assesses and enhances various aspects of our
                            church&apos;s operations to provide a seamless and uplifting experience for our congregation and
                            visitors. Join us as we strive for excellence in all we do, to the glory of God.</span>
                    </p>
                    <p><button type="button" className="btn btn-success btn-sm readbtn-minstry" role="button" name="readMore" id="readMore">Read more →</button></p>
                </div>

                <div className="col-md-4 col-sm-6 has-margin-bottom"> 
                <img className="img-responsive" src="/images/choir.png" alt="ministry sermon"/>
                    <h5>CHOIR UNIT</h5>
                    <p>Welcome to the heart of worship at Grace Definition Church - <b>Voice of Jacob</b>. With voices lifted in unison,
                        we create an atmosphere of praise and adoration that touches the very essence of our souls<span name="dots" id="dots">....</span>
                        <span name="more" id="more">. Led by passionate individuals who understand the power of music,
                            our Choir Unit plays a pivotal role in guiding the congregation into a deeper connection with God.
                            Our Choir Unit is more than a group – it&apos;s a family united in worship.
                            Join us as we use our voices to uplift, inspire, and draw closer to the heart of God.
                            Your voice matters, and together, we create melodies that resonate in heaven and on earth.</span>
                    </p>
                    <p><button type="button" className="btn btn-success btn-sm readbtn-minstry" role="button" name="readMore" id="readMore">Read more →</button></p>
                </div>

                <div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0 center-this has-margin-bottom"> 
                    <img className="img-responsive" src="./images/welfare.jpg" alt="bulletin programs"/>
                    <h5>WELFARE & HOSPITALITY</h5>
                    <p>At Grace Definition Church, we believe that showing Christ&apos;s love goes beyond words and
                        extends into the realm of action. Our Welfare and Hospitality Ministry exemplifies
                        this belief by ensuring that <span name="dots" id="dots">....</span>
                        <span name="more" id="more">everyone who walks through our doors experiences
                            a genuine sense of care, warmth, and belonging. Through acts of service and compassion,
                            we aim to create an environment where individuals feel valued, supported, and cherished.
                            The Welfare and Hospitality Ministry is open to all who have a heart for serving and
                            demonstrating God&apos;s love through action. If you have a heart for hospitality, compassion,
                            and making others feel valued, we invite you to join our team.</span>
                    </p>
                    <p><button type="button" className="btn btn-success btn-sm readbtn-minstry" role="button" name="readMore" id="readMore">Read more →</button></p>
                </div>

                <div className="col-md-4 col-sm-6 has-margin-bottom"> 
                    <img className="img-responsive" src="./images/security_protocol.jpg" alt="catholic church"/>
                    <h5>PROTOCOL & SECURITY</h5>
                    <p>At Grace Definition Church, we are committed to creating an environment where worship, fellowship,
                        and spiritual growth can flourish without distraction or concern. Our Protocol and Security teams
                        play <span name="dots" id="dots">....</span>
                        <span name="more" id="more">an essential role in maintaining order, ensuring safety, and upholding a sense of reverence
                            within our church community. If you have a heart for serving, maintaining order, and contributing to the safety of our
                            church community, we invite you to consider joining our Protocol and Security teams. Your commitment to these vital
                            roles helps us create an environment where God&apos;s presence can be encountered without distractions.</span>
                    </p>
                    <p><button type="button" className="btn btn-success btn-sm readbtn-minstry" role="button" name="readMore" id="readMore">Read more →</button></p>
                </div>

                <div className="col-md-4 col-sm-6 has-margin-bottom"> 
                    <img className="img-responsive" src="./images/drama and art.jpg" alt="ministry sermon"/>
                    <h5>CREATIVE ART/DRAMA</h5>
                    <p>Welcome to the world of Creative Art and Drama at Grace Definition Church - a realm
                        where creativity becomes a canvas to convey God&apos;s truth, inspire hearts, and bring the
                        stories of faith to life. Through visual <span name="dots" id="dots">....</span>
                        <span name="more" id="more">art, theater, and creative expression, we seek to
                            glorify God and touch lives in a unique and impactful way. If you have a passion for art, drama, or any
                            form of creative expression, consider joining the Creative Art and Drama Ministry. Whether
                            you&apos;re an experienced artist or someone who&apos;s curious about exploring your creative side,
                            we welcome you to be part of a community that celebrates God&apos;s gift of creativity.</span>
                    </p>
                    <p><button type="button" className="btn btn-success btn-sm readbtn-minstry" role="button" name="readMore" id="readMore">Read more →</button></p>
                </div>

                <div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0 center-this has-margin-bottom"> 
                    <img className="img-responsive" src="./images/evangelism.jpg" alt="bulletin programs"/>
                    <h5>EVANGELISM</h5>
                    <p>Welcome to the Evangelism Unit at Grace Definition Church - a passionate group of individuals dedicated to spreading
                        the message of God&apos;s love, grace, and salvation to the world. As ambassadors <span name="dots" id="dots">....</span>
                        <span name="more" id="more">of Christ, we embrace the call to share the Good News, reaching hearts and
                            transforming lives through intentional outreach and genuine care. Through the Evangelism Unit, we have the
                            privilege of being vessels through which God&apos;s love is shared with the world. Join us as we step out in faith,
                            inviting others to experience the hope and transformation that only Christ can bring. Your obedience to the call
                            of evangelism can impact lives for eternity.</span>
                    </p>
                    <p><button type="button" className="btn btn-success btn-sm readbtn-minstry" role="button" name="readMore" id="readMore">Read more →</button></p>
                </div>

                <div className="col-md-4 col-sm-6 has-margin-bottom"> 
                    <img className="img-responsive" src="./images/media_socialmedia.jpg" alt="catholic church"/>
                    <h5>MEDIA/SOCIAL MEDIA UNIT</h5>
                    <p>Welcome to the Media and Social Media Ministry at Grace Definition Church,
                        where we harness the power of technology and communication to spread the Word
                        of God, engage our community, and <span name="dots" id="dots">....</span>
                        <span name="more" id="more">create meaningful connections in the digital
                            realm. In this age of information, we embrace the opportunity to shine the light
                            of Christ through various media platforms. If you&apos;re tech-savvy, creative, or
                            have a passion for effective communication, consider joining the Media and
                            Social Media Ministry. Whether you&apos;re skilled in graphic design, content creation,
                            video editing, or simply have a heart for using technology to spread the Gospel,
                            there&apos;s a place for you here.</span>
                    </p>
                    <p><button type="button" className="btn btn-success btn-sm readbtn-minstry" role="button" name="readMore" id="readMore">Read more →</button></p>
                </div>

                <div className="col-md-4 col-sm-6 has-margin-bottom"> 
                    <img className="img-responsive" src="./images/logistic.jpg" alt="ministry sermon"/>
                    <h5>TRANSPORT AND LOGISTICS UNIT</h5>
                    <p>Welcome to the Transportation and Logistics Unit at Grace Definition Church,
                        where we make every effort to ensure that every member of our church community
                        can easily access our gatherings and <span name="dots" id="dots">....</span>
                        <span name="more" id="more">events. With a heart for service and a commitment
                            to convenience, we are dedicated to providing smooth transportation solutions and efficient
                            logistics management. Through the Transportation and Logistics Unit, we&apos;re able to demonstrate
                            the love of Christ by making our church gatherings accessible and convenient for all. Join us as
                            we serve with a heart of compassion, making it possible for everyone to connect and engage with our
                            church family. Your contribution in this area can have a lasting impact on the overall experience of
                            our congregation.</span>
                    </p>
                    <p><button type="button" className="btn btn-success btn-sm readbtn-minstry" role="button" name="readMore" id="readMore">Read more →</button></p>
                </div>

                <div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0 center-this has-margin-bottom"> 
                    <img className="img-responsive" src="./images/academics.jpg" alt="bulletin programs"/>
                    <h5>ACADEMICS UNIT</h5>
                    <p>Welcome to the Academic Unit at Grace Definition Church, where we believe that learning is a
                        lifelong journey that goes hand in hand with the growth of our faith. With a commitment to <span name="dots" id="dots">....</span>
                        <span name="more" id="more">knowledge,
                            education, and spiritual growth, we provide opportunities for intellectual and spiritual enrichment
                            within our church community. Through the Academic Unit, we embrace the idea that learning is an essential
                            part of our spiritual journey. Join us as we explore the richness of God&apos;s Word, engage in meaningful
                            conversations, and deepen our understanding of the mysteries of faith. Your curiosity and willingness
                            to learn can lead to a stronger, more vibrant walk with Christ.</span>
                    </p>
                    <p><button type="button" className="btn btn-success btn-sm readbtn-minstry" role="button" name="readMore" id="readMore">Read more →</button></p>
                </div>

                <div className="col-md-4 col-sm-8 col-sm-offset-2 col-md-offset-0 center-this has-margin-bottom"> 
                    <img className="img-responsive" src="./images/followup.jpg" alt="bulletin programs"/>
                    <h5>FOLLOW UP</h5>
                    <p>The Follow-Up Unit exists to build lasting relationships with newcomers and new believers, providing them with a
                        supportive community and the tools they need to deepen their understanding of
                        <span name="dots" id="dots">....</span>
                        <span name="more" id="more">God&apos;s Word and strengthen their
                            relationship with Him. Through the Follow-Up Unit, we strive to make every individual feel valued, supported,
                            and connected as they embark on their journey of faith. Join us as we walk alongside newcomers and new believers,
                            providing a foundation of care and encouragement that helps them flourish in their relationship with God.
                            Your willingness to invest in their spiritual growth can have a lasting impact on their lives.</span>
                    </p>
                    <p><button type="button" className="btn btn-success btn-sm readbtn-minstry" role="button" name="readMore" id="readMore">Read more →</button></p>
                </div>
            </div>
        </div>
    )
}

export default AllministryPage
