

import { useEffect, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay';


function Media() {
  const [video, setVideo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [refreshKey, setRefreshKey] = useState(0);


  const fetchVideo = async () => {
    console.log(111);
    setIsLoading(true);
    fetch('https://www.googleapis.com/youtube/v3/search?key=AIzaSyDMOXKUk2IsQtxuPnPnBd-X2LLGdETeftE&channelId=UCdkA_GWhhJAGY5z1dDWarLw&part=snippet,id')
        .then(res => res.json())
        .then((res) => {
            console.log();
            // const fetchedBanners = res.data.data;
            // setVideo(res.items);
            if (res.items.length > 0) {
                res.items = [...res.items];
                res.items.shift(); // Remove the first item
                setVideo(res.items);
            }
            setIsLoading(false);
        })
};

// const handleRefresh = () => {
//   setRefreshKey((prevRefreshKey) => prevRefreshKey + 1);
// };
const shareVideo = (videoId) => {
  // Create a shareable link using the videoId
  const shareableLink = `https://www.youtube.com/watch?v=${videoId}?feature=share`;
  window.open(shareableLink, '_blank');
};

const downloadVideo = (videoId) => {
  // Create a shareable link using the videoId
  const downloadVideoLink = `https://www.ssyoutube.com/watch?v=${videoId}`;
  window.open(downloadVideoLink, '_blank');
};

useEffect(() => {
  fetchVideo();
}, []);
  return (

  <div>
    <LoadingOverlay
      active={isLoading}
      spinner
      text='Loading. Please, wait...'
      ></LoadingOverlay>
    {/* Hero section for sermons */}
    <div className="subpage-head has-margin-bottom sermons-section">
      <div>
        <h3>Sermon</h3>
        <p className="lead">You cannot, but God Can. Rest in His work</p>
      </div>
    </div>
    {/* Hero section for sermons ends here */}

    <section>
      <div className="sermonslist">
        <div className="container">
          <div className="sermonslist-flex">
            {video.length === 0 ? (
              <div
                style={{
                  height: '200px',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  alignItems: 'center',
                  fontSize: '25px',
                  fontWeight: 700,
                }}
              >
                No Sermon Found.
              </div>
            ) : (
              video.map((v, i) => (
                <div key={`media-${i}`} className="sermonslist-flex-items">
                  <iframe
                  className='sermonslist-flex-bg'
                    title={`Video ${i}`}
                    width="100%"
                    height="200"
                    src={`https://www.youtube.com/embed/${v.id.videoId}`}
                    frameBorder="0"
                    allowFullScreen
                   
                  ></iframe>
                  <div className="sermonslist-flex-details">
                    <p className="sermonslist-flex-details-title sermonslist-flex-details-width">
                      {v.snippet.title}
                    </p>
                    {/* <p className="sermonslist-flex-details-preacher sermonslist-flex-details-width">
                      Preacher Name
                    </p> */}
                    <div className="sermonslist-flex-details-width">
                      <p className="sermonslist-flex-details-date">
                        {v.snippet.publishedAt}
                      </p>
                    </div>
                    <audio src="your_audio_src.mp3" controls />
                    <div className="sermonslist-flex-details-audioinfo sermonslist-flex-details-width">
                      <div className="sermons-audios-icon-properties">
                        <i className="fas fa-download" onClick={() => downloadVideo(v.id.videoId)}  style={{ cursor: 'pointer' }}> 
                        </i>
                        <i className="fas fa-share" onClick={() => shareVideo(v.id.videoId)}
                            style={{ cursor: 'pointer' }}/>
                      </div>
                      <p>Church Sermon</p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}

export default Media




// import { useEffect, useState } from 'react'
// import LoadingOverlay from 'react-loading-overlay';


// function Media() {
//   const [video, setVideo] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [selectedVideo, setSelectedVideo] = useState(null);


//   const fetchVideo = async () => {
//     console.log(111);
//     setIsLoading(true);
//     fetch('https://www.googleapis.com/youtube/v3/search?key=AIzaSyDMOXKUk2IsQtxuPnPnBd-X2LLGdETeftE&channelId=UCdkA_GWhhJAGY5z1dDWarLw&part=snippet,id')
//         .then(res => res.json())
//         .then((res) => {
//             console.log();
//             if (res.items.length > 0) {
//                 res.items = [...res.items];
//                 res.items.shift(); 
//                 setVideo(res.items);
//             }
//             setIsLoading(false);
//         })
// };

// const openVideoModal = (v) => {
//   console.log("Opening video modal for", v);
//   setSelectedVideo(v);
// };

// const closeVideoModal = () => {
//   setSelectedVideo(null);
// };

// useEffect(() => {
//   fetchVideo();
// }, []);
//   return (

//   <div>
//     <LoadingOverlay
//                 active={isLoading}
//                 spinner
//                 text='Loading. Please, wait...'
//       ></LoadingOverlay>
//     {/* Hero section for sermons */}
//     <div className="subpage-head has-margin-bottom sermons-section">
//       <div>
//         <h3>Sermon</h3>
//         <p className="lead">You cannot, but God Can. Rest in His work</p>
//       </div>
//     </div>
//     {/* Hero section for sermons ends here */}

//     <section>
//       <div className="sermonslist">
//         <div className="container">
//           <div className="sermonslist-flex">
//             {video.length === 0 ? (
//               <div
//                 style={{
//                   height: '200px',
//                   display: 'flex',
//                   justifyContent: 'center',
//                   width: '100%',
//                   alignItems: 'center',
//                   fontSize: '25px',
//                   fontWeight: 700,
//                 }}
//               >
//                 No Sermon Found.
//               </div>
//             ) : (
//               video.map((v, i) => (
//                 <div key={`media-${i}`} className="sermonslist-flex-items">
                  
//                   <div
//                       className="sermonslist-flex-bg"
//                       onClick={() => openVideoModal(v)}
//                       style={{ backgroundImage: `url(${v.snippet.thumbnails.medium.url})` }}
//                     >
//                       <i className="fa fa-play"></i>
//                     </div>
//                   <div className="sermonslist-flex-details">
//                     <p className="sermonslist-flex-details-title sermonslist-flex-details-width">
//                       {v.snippet.title}
//                     </p>
//                     <p className="sermonslist-flex-details-preacher sermonslist-flex-details-width">
//                       {/* Replace with preacher information */}
//                       Preacher Name
//                     </p>
//                     <div className="sermonslist-flex-details-width">
//                       <p className="sermonslist-flex-details-date">
//                         {v.snippet.publishedAt}
//                       </p>
//                     </div>
//                     <audio  src={`https://www.youtube.com/embed/${v.id.videoId}`} controls />
//                     <div className="sermonslist-flex-details-audioinfo sermonslist-flex-details-width">
//                       <div className="sermons-audios-icon-properties">
//                         <i className="fa fa-download" />
//                         <i className="fa fa-share" />
//                       </div>
//                       <p>{/* Replace with program name */}Program Name</p>
//                     </div>
//                   </div>
//                 </div>
//               ))
//             )}
//           </div>
//         </div>
//       </div>
//     </section>
//     {selectedVideo && (
//         <div className="video-modal">
//           <div className="video-modal-content">
//             <span className="close-video-modal" onClick={closeVideoModal}>
//               &times;
//             </span>
//             <iframe
//               title={`Video ${selectedVideo.id.videoId}`}
//               width="100%"
//               height="400"
//               src={`https://www.youtube.com/embed/${selectedVideo.id.videoId}`}
//               frameBorder="0"
//               allowFullScreen
//             ></iframe>
//           </div>
//         </div>
//       )}
//   </div>
//   )
// }

// export default Media

