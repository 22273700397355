import { ThreeDots } from "react-loader-spinner";
import './Loading.css';

const LoadingPage = () => {
  return (
    <div className="d-flex align-items-center justify-content-center loader" style={{ height: '100vh' }}>
      <ThreeDots color="#dark" />
    </div>
  );
};

export default LoadingPage;
